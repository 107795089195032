<template>
    <div
        ref="sidebar"
        class="z-50 fixed inset-0 w-screen lg:flex-grow-0 lg:flex-shrink-0 lg:basis-[30rem] lg:static lg:w-auto"
        :class="{ 'sidebar-force-open': forceOpen, 'sidebar-force-close': !forceOpen, 'sidebar-loading': !isLoaded }"
    >
        <aside
            class="w-full h-full bg-black text-white lg:flex flex-col flex-grow p-7 pt-5 pb-3 lg:p-20 lg:pb-10"
            :class="{
                '!bg-christmas': user?.special_events?.christmas,
            }"
        >
            <div class="sticky top-0 lg:top-5 flex flex-col justify-between overflow-y-auto h-full">
                <div>
                    <div class="-mx-7 -mt-4 lg:hidden max-w-full">
                        <account-sidebar-toggle :white="true" />
                    </div>

                    <h2
                        class="text-left my-0 uppercase text-2xl"
                        v-text="$t('sidebar.headline')"
                    />
                    <p
                        class="uppercase text-sm mt-12 font-serif text-white/80"
                        :class="{
                            'text-color-premium': user?.loyalty_status === 'premium',
                            'text-color-superior': user?.loyalty_status === 'superior',
                            'text-color-royal': user?.loyalty_status === 'royal',
                        }"
                    >
                        {{ $t("sidebar.status_" + (user?.loyalty_status ?? "premium")) }}

                        <layout-tooltip
                            position="right"
                            position-alternate="bottom"
                            class="text-white"
                        >
                            <template #tooltip>
                                <p
                                    class="mb-4 text-sm"
                                    v-text="$t('sidebar.valid_to_tooltip.headline', { date: cardValidUntil })"
                                />
                                <p
                                    class="text-sm"
                                    v-text="$t('sidebar.valid_to_tooltip.description')"
                                />
                            </template>

                            <template #icon>
                                <icon-info-black class="w-5 h-5 block" />
                            </template>

                            <template #default>
                                <icon-info class="w-5 h-5 p-1 inline-block relative -top-2 -left-1" />
                            </template>
                        </layout-tooltip>
                    </p>
                    <h3
                        class="text-left my-0 leading-tight !tracking-wide font-serif text-3xl text-white/80"
                        :class="{
                            'text-color-premium': user?.loyalty_status === 'premium',
                            'text-color-superior': user?.loyalty_status === 'superior',
                            'text-color-royal': user?.loyalty_status === 'royal',
                        }"
                    >
                        {{ $t("sidebar.welcome") }}<br />
                        {{ user?.name ?? "Unbekannt" }}
                    </h3>

                    <p
                        class="text-sm mt-4 text-white/80"
                        :class="{
                            'text-color-premium': user?.loyalty_status === 'premium',
                            'text-color-superior': user?.loyalty_status === 'superior',
                            'text-color-royal': user?.loyalty_status === 'royal',
                        }"
                        v-text="$t('sidebar.member_since', { date: memberSince })"
                    />

                    <nav class="mt-10">
                        <ul>
                            <li
                                v-for="item in navigation"
                                :key="item.text"
                            >
                                <nuxt-link
                                    :to="localePath(item.links[0])"
                                    class="nav-item cursor-pointer block uppercase py-2 px-4 my-2 border-l-[3px] border-transparent"
                                    :class="{ 'nav-item-active': isActive(item) }"
                                    @click="closeOnMobile"
                                >
                                    {{ $t(item.text) }}
                                </nuxt-link>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div>
                    <div
                        v-if="user?.special_events?.christmas"
                        class="relative w-2/3"
                    >
                        <img
                            src="/images/sidebar/christmas-halo.svg"
                            alt=""
                            class="absolute z-0 top-0 left-0 w-full h-full"
                        />

                        <img
                            src="/images/sidebar/christmas.svg"
                            :alt="$t('sidebar.christmas_tree')"
                            class="relative z-10 w-full h-auto"
                        />
                    </div>
                </div>
            </div>
        </aside>
    </div>
</template>

<script setup lang="ts">
import type { KadeweUser } from "~/types";
import IconInfo from "~/public/icons/info.svg";
import IconInfoBlack from "~/public/icons/info_black.svg";

const user = useSanctumUser<KadeweUser>();
const localePath = useLocalePath();
const sidebarStore = useSidebarStore();
const route = useRoute();
const sidebar = ref<HTMLDivElement | null>(null);
const { forceOpen } = storeToRefs(sidebarStore);
const isLoaded = ref(false);
const i18nLocale = useI18n();
const dayjs = useDayjs();

interface NavigationItem {
    text: string;
    links: string[];
}

const navigation = ref<NavigationItem[]>([
    { text: "sidebar.portal", links: ["/portal/card"] },
    { text: "sidebar.status", links: ["/portal/status"] },
    { text: "sidebar.rewards", links: ["/portal/rewards", "/portal/coupons", "/portal/parking"] },
    { text: "sidebar.events", links: ["/portal/events"] },
    { text: "sidebar.account", links: ["/portal/account"] },
    { text: "sidebar.notifications", links: ["/portal/notifications"] },
    { text: "sidebar.help", links: ["/portal/help"] },
    { text: "sidebar.logout", links: ["/portal/logout"] },
]);

const isActive = (item: NavigationItem): boolean => {
    for (const link of item.links) {
        if (route.path.startsWith(link)) {
            return true;
        }
    }

    return false;
};

const locale = computed<string>(() => {
    return i18nLocale.locale.value;
});

const memberSince = computed(() => {
    if (!user.value?.create_date) {
        return "/";
    }

    return new Date(user.value?.create_date ?? "").toLocaleDateString(locale.value);
});

const closeOnMobile = () => {
    sidebarStore.closeSidebar();
};

onMounted(() => {
    window.setTimeout(() => {
        isLoaded.value = true;
    }, 300);
});

const cardValidUntil = computed(() => {
    if (!user.value?.current_card_valid_until) {
        return "/";
    }

    return dayjs.utc(user.value.current_card_valid_until).format("MM.YYYY");
});
</script>

<style scoped>
.nav-item:hover,
.nav-item-active {
    @apply font-medium tracking-wide;

    /** Linear gradient from top to bottom, so that the border is only in the center 50% */
    border-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 75%,
            rgba(255, 255, 255, 0) 75%,
            rgba(255, 255, 255, 0) 100%
        )
        0 100%;
}

.sidebar-force-open {
    transform: translateX(-100%);
    animation: slide-in-left 0.3s forwards;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.sidebar-force-close {
    transform: translateX(0);
    animation: slide-out-left 0.3s forwards;
}

.sidebar-force-close.sidebar-loading {
    animation-duration: 0s;
}

.sidebar-loading {
    visibility: hidden;
}

@screen lg {
    .sidebar-force-open,
    .sidebar-force-close {
        transform: translateX(0);
        animation: none;
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.text-color-superior {
    background: linear-gradient(
        281.38deg,
        rgba(162, 162, 162, 0.8) 5.52%,
        rgba(241, 243, 243, 0.8) 47.66%,
        rgba(223, 223, 223, 0.8) 88.7%
    );
    /** Move apply statement to override bg clip text */
    @apply bg-clip-text;
    -webkit-text-fill-color: transparent;
}

.text-color-royal {
    background:
        linear-gradient(0deg, #eaada0, #eaada0),
        linear-gradient(278.52deg, #b88172 -36.7%, #f9d0c2 48.45%, #eaada0 102.94%);
    @apply bg-clip-text;
    -webkit-text-fill-color: transparent;
}
</style>
