<template>
    <div
        class="flex-1 flex-grow group"
        :class="{
            'cursor-pointer': !empty,
        }"
        @click="clickModal"
    >
        <div
            class="flex flex-col items-center bg-beige-warm/50 border-2 border-transparent hover:border-black transition-colors rounded-2xl py-3 px-4"
        >
            <p
                v-if="empty === true"
                class="py-8 sm:py-16 font-semibold text-center"
                v-text="$t('portal.address_verification_modal.empty_address')"
            />
            <template v-else>
                <h4
                    class="font-semibold mb-4 text-larger"
                    v-text="title"
                />

                <p
                    v-if="streetText"
                    v-text="streetText"
                />
                <p v-if="zip || city">
                    <span
                        v-if="zip"
                        v-text="zip"
                    />
                    <span v-if="zip && city">&nbsp;</span>
                    <span
                        v-if="city"
                        v-text="city"
                    />
                </p>
                <p
                    v-if="country"
                    v-text="countryTranslation"
                />

                <form-button
                    v-if="action"
                    type="primary"
                    class="mt-8 sm:mb-3 group-hover:bg-gray-600"
                >
                    {{ action }}
                </form-button>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PlacekitStreet } from "~/types";

const props = defineProps<{
    title: string;
    empty?: boolean;
    street?: string | PlacekitStreet;
    zip?: string;
    city?: string;
    country?: string;
    action?: string;
}>();

const emit = defineEmits(["click"]);

const { t } = useI18n();

const streetText = computed(() => {
    if (typeof props.street === "string") {
        return props.street;
    }

    if (!props.street?.name || !props.street?.number) {
        return "";
    }

    return `${props.street?.name} ${props.street?.number}`;
});

const countryTranslation = computed(() => {
    if (!props.country) {
        return "";
    }

    return t(`countries.${props.country.toUpperCase()}`);
});

const clickModal = () => {
    if (!props.empty) {
        emit("click");
    }
};
</script>
