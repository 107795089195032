<template>
    <div>
        <layout-modal
            :show="show"
            size="sm:max-w-sm md:max-w-lg lg:max-w-3xl sm:px-12"
            :loading="loading"
            @closing="close()"
        >
            <template #title>
                <span
                    class="text-xl sm:text-2xl uppercase font-medium leading-tight font-sans tracking-wide"
                    v-text="$t('portal.address_verification_modal.title')"
                />
            </template>

            <template #default>
                <div class="flex flex-col items-center">
                    <p
                        class="mb-8"
                        v-text="$t('portal.address_verification_modal.subtitle')"
                    />

                    <p
                        v-if="errorMessage"
                        class="mb-8 text-red-500"
                        v-text="errorMessage"
                    />

                    <template v-if="loading">
                        <loading-animation />
                    </template>
                    <template v-else>
                        <div
                            class="flex flex-col w-full gap-4 sm:gap-6 justify-between lg:flex-row"
                            :class="{
                                'lg:px-24': user?.valid_address.suggestion_is_same_address,
                            }"
                        >
                            <account-address-verification-address
                                :title="$t('portal.address_verification_modal.provided_address')"
                                :empty="user?.valid_address.empty"
                                :street="user?.street"
                                :zip="user?.zip"
                                :city="user?.city"
                                :country="user?.country"
                                :action="$t('portal.address_verification_modal.confirm_address')"
                                @click="confirm()"
                            />

                            <account-address-verification-address
                                v-if="!user?.valid_address.suggestion_is_same_address && user?.valid_address.suggestion"
                                :title="$t('portal.address_verification_modal.recommended_address')"
                                :street="user.valid_address.suggestion?.street"
                                :zip="user.valid_address.suggestion?.zip"
                                :city="user.valid_address.suggestion?.city"
                                :country="user.valid_address.suggestion?.country"
                                :action="$t('portal.address_verification_modal.use_recommended_address')"
                                @click="useRecommended(user.valid_address.suggestion)"
                            />
                        </div>

                        <p
                            v-if="!user?.valid_address.empty"
                            class="mt-4 sm:mt-8 mb-0"
                            v-text="$t('portal.address_verification_modal.or')"
                        />

                        <form-button
                            class="mt-4 sm:mt-8"
                            type="default"
                            @click="navigateTo(localePath('/portal/account'))"
                        >
                            <template v-if="user?.valid_address.empty">
                                {{ $t("portal.address_verification_modal.add_address") }}
                            </template>
                            <template v-else>
                                {{ $t("portal.address_verification_modal.change_address") }}
                            </template>
                        </form-button>
                    </template>
                </div>
            </template>
        </layout-modal>
    </div>
</template>

<script setup lang="ts">
import type { KadeweUser } from "~/types";

const user = useSanctumUser<KadeweUser>();
const show = ref(false);
const cookieStore = useCookieStore();
const localePath = useLocalePath();
const route = useRoute();
const client = useSanctumClient();
const { refreshIdentity } = useSanctumAuth();
const { t } = useI18n();

const errorMessage = ref<string | null>(null);
const loading = ref(false);
const closing = ref(false);

const setShowValue = () => {
    if (route.path.includes("/portal/account")) {
        show.value = false;
        return;
    }

    show.value = user.value?.valid_address.display_modal || false;

    if (show.value) {
        cookieStore.trackEvent("address-verification-show", {
            has_suggestion: user.value?.valid_address.suggestion !== null,
            suggestion_is_same_address: user.value?.valid_address.suggestion_is_same_address,
        });
    }
};

const confirm = async () => {
    loading.value = true;
    errorMessage.value = null;

    try {
        await client("/api/user/address/confirm", {
            method: "PUT",
        });

        cookieStore.trackEvent("address-verification-confirm");

        await refreshIdentity();
    } catch (error) {
        console.error(error);
        errorMessage.value = t("portal.address_verification_modal.confirm_error");
    } finally {
        loading.value = false;
    }
};

const useRecommended = async (address: KadeweUser["valid_address"]["suggestion"]) => {
    loading.value = true;
    errorMessage.value = null;

    try {
        await client("/api/user/address/recommended", {
            method: "PUT",
            body: JSON.stringify(address),
        });

        cookieStore.trackEvent("address-verification-recommended");

        await refreshIdentity();
    } catch (error) {
        console.error(error);
        errorMessage.value = t("portal.address_verification_modal.use_recommended_address_error");
    } finally {
        loading.value = false;
    }
};

const close = async () => {
    if (closing.value) {
        console.warn("Already closing");
        return;
    }

    loading.value = true;
    closing.value = true;

    try {
        await client("/api/user/address/close", {
            method: "POST",
        });

        cookieStore.trackEvent("address-verification-abort");

        await refreshIdentity();
    } catch (error) {
        console.error(error);
        loading.value = false;
    } finally {
        closing.value = false;
        show.value = false;
    }
};

watch(user, () => {
    setShowValue();
});

watch(route, () => {
    setShowValue();
});

setShowValue();
</script>
