<template>
    <account-card-qr-code-modal ref="cardQrCodeModal" />
    <account-loyalty-reward-qr-code-modal
        v-if="latestReward"
        ref="rewardQrCodeModal"
        :reward="latestReward"
        type="voucher"
        :active="true"
    />

    <div
        class="fixed lg:hidden z-[60]"
        :class="{
            'inset-0 bg-black/90': isOpen,
            'bottom-0 right-0 mr-4 mb-4': !isOpen,
        }"
    >
        <layout-round-button
            v-if="!isOpen"
            :title="$t('floating_action_button.open')"
            @click="openMenu"
        >
            <icon-qr-code class="w-8 h-auto" />
        </layout-round-button>

        <div
            v-if="isOpen"
            class="flex flex-col justify-between h-dvh p-4"
        >
            <div>
                <div class="flex justify-end">
                    <layout-round-button
                        :title="$t('floating_action_button.close')"
                        class="!w-12 !h-12"
                        @click="closeMenu"
                    >
                        <icon-times class="w-5 h-auto" />
                    </layout-round-button>
                </div>

                <h3 class="text-white mt-12 text-2xl">
                    {{ $t("floating_action_button.headline_1") }}<br />
                    {{ $t("floating_action_button.headline_2") }}
                </h3>
            </div>
            <div class="text-white text-sm uppercase">
                <div
                    class="flex justify-end items-center cursor-pointer"
                    @click="openLoyaltyCardModal"
                >
                    <h4 v-text="$t('floating_action_button.open_loyalty_card')" />

                    <layout-round-button
                        class="ml-4"
                        :title="$t('floating_action_button.open_loyalty_card')"
                    >
                        <icon-qr-code class="w-8 h-auto text-base text-black" />
                    </layout-round-button>
                </div>
                <div
                    v-if="latestReward"
                    class="flex justify-end items-center cursor-pointer mt-8"
                    @click="openRewardModal"
                >
                    <h4 v-text="$t('floating_action_button.open_reward')" />

                    <layout-round-button
                        class="ml-4"
                        :title="$t('floating_action_button.open_reward')"
                    >
                        <icon-qr-code class="w-8 h-auto text-base text-black" />
                    </layout-round-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Reward } from "~/types";
import { AccountCardQrCodeModal, AccountLoyaltyRewardQrCodeModal } from "#components";
import IconQrCode from "~/public/icons/qr_code.svg";
import IconTimes from "~/public/icons/times.svg";

const client = useSanctumClient();
const cookieStore = useCookieStore();

const isOpen = ref(false);
const rewards = ref<{
    active: Reward[];
    inactive: Reward[];
} | null>(null);
const cardQrCodeModal = ref<InstanceType<typeof card> | null>(null);
const rewardQrCodeModal = ref<InstanceType<typeof AccountLoyaltyRewardQrCodeModal> | null>(null);

const openMenu = () => {
    isOpen.value = !isOpen.value;
    cookieStore.trackEvent("open_floating_action_button");
};

const closeMenu = () => {
    isOpen.value = false;
    cookieStore.trackEvent("close_floating_action_button");
};

const openLoyaltyCardModal = () => {
    if (!cardQrCodeModal.value) {
        return;
    }

    cookieStore.trackEvent("open_card_qr_code_modal", {
        location: "floating_action_button",
    });
    isOpen.value = false;
    cardQrCodeModal.value.open();
};

const openRewardModal = () => {
    if (!rewardQrCodeModal.value) {
        return;
    }

    cookieStore.trackEvent("open_reward_qr_code_modal", {
        location: "floating_action_button",
    });
    isOpen.value = false;
    rewardQrCodeModal.value.open();
};

onMounted(async () => {
    try {
        rewards.value = await client("/api/rewards");
    } catch (error) {
        console.error(error);
    }
});

const latestReward = computed(() => {
    if (!rewards.value?.active) {
        return null;
    }

    if (rewards.value.active.length === 0) {
        return null;
    }

    // Get the reward with the most recent year and the highest value
    return Object.values(rewards.value.active).reduce((prev: Reward, current: Reward) => {
        if (prev.year > current.year) {
            return prev;
        }

        if (prev.year < current.year) {
            return current;
        }

        return prev.value > current.value ? prev : current;
    });
});

watch(isOpen, (value) => {
    if (value) {
        document.body.classList.add("overflow-hidden");
    } else {
        document.body.classList.remove("overflow-hidden");
    }
});
</script>
