<template>
    <div
        v-click-outside="hide"
        class="tooltip-wrapper inline-block font-sans text-sm font-normal normal-case leading-normal"
        style="letter-spacing: 0"
        @mouseover="show"
        @mouseleave="hide"
        @click="show"
    >
        <div
            v-if="isVisible"
            ref="arrowElement"
            class="tooltip-arrow"
            :style="tooltipArrowStyles"
        />

        <span
            ref="triggerElement"
            class="cursor-help"
        >
            <slot name="default" />
        </span>

        <div
            v-if="isVisible"
            ref="tooltipElement"
            class="tooltip-content absolute top-0 left-0 z-50 bg-white text-black shadow-lg max-w-full lg:w-80"
            :style="floatingStyles"
            @click="hide"
        >
            <div class="p-6 flex flex-row gap-4">
                <div
                    v-if="slots.icon"
                    class="w-5"
                >
                    <slot name="icon" />
                </div>

                <div>
                    <slot name="tooltip" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { arrow, shift, autoPlacement, useFloating, autoUpdate } from "@floating-ui/vue";
import type { Placement } from "@floating-ui/vue";

const slots = useSlots();

const props = withDefaults(
    defineProps<{
        position: Placement;
        positionAlternate: Placement;
    }>(),
    {
        position: "right",
        positionAlternate: "bottom",
    },
);

const isVisible = ref(false);
const arrowElement = ref<HTMLElement | null>(null);
const triggerElement = ref<HTMLElement | null>(null);
const tooltipElement = ref<HTMLElement | null>(null);
const { floatingStyles, middlewareData, placement } = useFloating(triggerElement, tooltipElement, {
    placement: props.position,
    transform: false,
    whileElementsMounted(...args) {
        return autoUpdate(...args, { animationFrame: true });
    },
    middleware: [
        autoPlacement({
            allowedPlacements: [props.position, props.positionAlternate],
        }),
        shift(),
        arrow({ element: arrowElement }),
    ],
});

const show = () => {
    isVisible.value = true;
};

const hide = () => {
    isVisible.value = false;
};

const tooltipArrowStyles = computed(() => {
    let borderColor = null;
    if (placement.value === "right") {
        borderColor = "transparent white transparent transparent";
    } else if (placement.value === "left") {
        borderColor = "transparent transparent transparent white";
    } else if (placement.value === "top") {
        borderColor = "white transparent transparent transparent";
    } else if (placement.value === "bottom") {
        borderColor = "transparent transparent white transparent";
    }

    return {
        left: middlewareData.value.arrow?.x != null ? `${middlewareData.value.arrow.x}px` : "",
        top: middlewareData.value.arrow?.y != null ? `${middlewareData.value.arrow.y}px` : "",
        "--tooltipArrowBorderColor": borderColor,
    };
});

onMounted(() => {
    window.addEventListener("scroll", hide);
    window.addEventListener("resize", hide);
});

onUnmounted(() => {
    window.removeEventListener("scroll", hide);
    window.removeEventListener("resize", hide);
});
</script>

<style scoped>
.tooltip-wrapper {
    @apply bg-clip-border bg-none;

    -webkit-text-fill-color: currentColor;
}
.tooltip-arrow {
    @apply absolute w-2 h-2;
    /** Hack because the sidebar "i" icon is relatively positioned at a slightly different position */
    transform: translate(-7px, 5px);
}
.tooltip-arrow::after {
    @apply absolute border-8 border-solid;
    content: "";
    border-color: var(--tooltipArrowBorderColor);
}
</style>
